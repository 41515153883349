$topbar-responsive-bg: $primary-color;
$topbar-responsive-animation-type: fade-in;
a.link {
    text-decoration: underline;
    &:hover {
        color: $secondary-color;
        transition: all 0.5s;
    }
}
.guide {
    padding-left: 10px;
    padding-right: 10px;}
.link-gray {
    &:hover {
        color: #2d2d2c !important;
        transition: all 0.5s;
    }
}
div#hamburger-1 {
    width: rem-calc(34px);
    height: rem-calc(36px);
    position: relative;
    top: rem-calc(8px);
    right: rem-calc(-7px);
    float: right;
}

.none {
    display: none !important;
}

.close-button {
    right: 28px;
}
.backtop-href {
    max-width: 30px;
    min-width: 30px;
    display: inherit;
    text-align: center;
    margin-top: rem-calc(-8);
    position: relative;
}
.button.success:hover, .button.success:focus {
    background-color: $secondary-color;
    color: #ffffff;
}
// vimeo
.embed-container {
    position: relative;
    overflow: hidden;
    width:100%;
    margin: auto;
    img {
        width: 100%;
    }
  }
  
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      display: none;
  }
  //end vimeo


// Replace all SVG images with inline SVG
.backtop {
    &:hover {
        path {
        fill: $secondary-color !important;
        transition: all 0.5s;
        }
    }
}
.play-vimeo {
    width: rem-calc(110);
    position: absolute;
    top: calc(50% - 55px);
    left: calc(50% - 55px);
    transition: all 0.5s;
    &:hover {
        ellipse {
            stroke: $success-color !important;
            transition: all 0.5s;
        }
        polygon {
            stroke: $success-color !important;
            transition: all 0.5s;
        }
    }
}
.menu.simple li + li {
    margin-left: 0 !important;
}

.menu-mobile-mc {
    min-height: 93vh;
    display: grid;
    grid-auto-rows: 1fr 1fr 1fr;
    li {
        padding: 0 !important;
        a {font-weight: 600;}

        &:first-child {
            align-self: center;
            padding: 0;
            margin:0;

        }
        &:nth-child(2) {
            padding-top: 40px !important;
            align-self: start;
            padding: 0;
            margin:0;



        }
        &:nth-child(3) {
            align-self: start;
            padding: 0;
            padding-bottom: rem-calc(200)


        }
    }
}
.backtop path {
    transition: all 0.5s;
}

svg.back{
    &:hover {
        polygon {
            fill: $alert-color !important;
            transition: all 0.5s;
        }
    }
}
.back {
    transition: all 0.5s;
}
.grid-container {
    max-width: rem-calc(930);
}
.title {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.5px;
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(25);
    position: relative;
    &:after {
        position: absolute;
        top: rem-calc(-15);
        left: calc(50% - 35px);
        content: "";
        width: rem-calc(70);
        height: 100%;
        border-bottom: 5px solid $secondary-color
    }
}
.blog-text {
    font-size: rem-calc(18);
}
.gtk-logo {
    width: 200px !important;
    position: relative !important;
    top: 0px !important;
}
.blog-tag {
    font-size: rem-calc(12);
    color: $white;
    background: $primary-color;
    text-transform: uppercase;
    display: initial;
    padding: rem-calc(2 4);
}
.blog-subtitle {
    font-size: rem-calc(20);
    font-weight: 800;
    line-height: rem-calc(27);
    margin-top: rem-calc(20);

}
.first-letter {
    overflow-wrap: break-word;
    font-size: rem-calc(52);
    line-height: rem-calc(54);
    display: block;
    position: relative;
    float: left;
    margin: 0px 7px 0 -5px;
    color: $alert-color;
  }
.blog-subtitle-desc {
    font-size: rem-calc(23);
    font-weight: 800;
    margin-top: rem-calc(35);
    line-height: 25px !important;

}
.pin-tab-quotation {
    font-style: italic;
    font-size: rem-calc(24);
    font-weight: bold;
    line-height: rem-calc(28);
    text-align: center;
    padding: rem-calc(20 100);
}
.card {
    width: 100%;
    background: $secondary-color;
    margin-top: rem-calc(25);
    margin-bottom: rem-calc(25);
    border: none;
    text-decoration: none;
    display: grid;

    img {
        transform: scale(1);
        transition: all 0.5s;
        &:hover {
            transform: scale(1.1);
            transition: all 0.5s;
        }
    }

    .card-section {
        box-sizing: border-box;
        text-align: center;
        background: $secondary-color;
        position: relative;
        z-index: 500;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;

        .card-logo {
            box-sizing: border-box;
            text-align:  center;
            width: 40%;
            position: relative;
            margin-top: rem-calc(-25);
            transition: none;
            transform: scale(1);
            align-self: center;
            justify-self: center;

        }
        .card-text {
            box-sizing: border-box;
            font-weight: bold;
            font-size: rem-calc(20);
            line-height: rem-calc(25);
            text-align: center;
            letter-spacing: 0.5px;
            position: relative;
            margin-top: rem-calc(-10);
            align-self: center;
            justify-self: center;
            min-height: 60px;
            position: relative;
        }
        .card-button {
            border: 4px solid transparent;
            box-sizing: border-box;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-size: rem-calc(12);
            font-weight: 600;
            align-self: end;
            justify-self: center;

        }
    }
}

.header {
    background: $secondary-color;
    text-align: center;
    padding-top: rem-calc(80 + 80);
    padding-bottom: rem-calc(80);
    @include breakpoint(930) {
        padding-top: rem-calc(280);
        padding-bottom: rem-calc(200);
    }
    .grid-container {
        .logo {
            width: 60%;
            margin-bottom: rem-calc(30);
            @include breakpoint(930) {
                width: 55%;
                margin-bottom: 0;
            }
        }
        p {
            padding-top:rem-calc(30);
            margin-bottom: 0;
            font-size: rem-calc(22);
            font-weight: 600;
            letter-spacing: 0.5px;
            line-height: rem-calc(24);
        }
        .title-by {
            padding-top: rem-calc(25);
            font-size: rem-calc(16);
            font-weight: 500;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            position: relative;
            top: rem-calc(10);
            margin-bottom: rem-calc(10);
            @include breakpoint(930) {
                margin-bottom: 0;
            }
        }
        ul {
            display: grid;
            grid-template-columns: 33% 33% 33%;
            list-style-type: none;
            li {

                &:first-child {
                    padding-left: 0;
                    img {
                        width: 100px;
                        height: 100px;
                        position: relative;
                        top: 0 !important;
                    }
                }
                &:nth-child(2) {
                    padding-right: 0;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
                &:nth-child(3) {
                    padding-right: 0;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
                &:nth-child(4) {
                    padding-right: 0;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
                &:nth-child(5) {
                    padding-right: 0;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
                &:nth-child(6) {
                    padding-right: 0;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
            @include breakpoint(930) {
                list-style-type: none;
                display: inline-flex;
                margin: 0;
                li {
                    &:first-child {
                        padding-right: 15px;
                        img {
                            width: 100%;
                            position: relative;
                            top: 4px;
                        }

                    }
                    &:nth-child(2) {
                        padding-right: 15px;
                        img {
                            width: 100%;
                        }
                    }
                    &:nth-child(3) {
                        padding-right: 15px;
                        img {
                            width: 100%;
                        }
                    }
                    &:nth-child(4) {
                        padding-right: 15px;
                        img {
                            width: 100%;
                        }
                    }
                    &:nth-child(5) {
                        padding-right: 15px;
                        img {
                            width: 100%;
                        }
                    }
                    &:nth-child(6) {
                        padding-right: 0;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(930) {
        padding-top: rem-calc(120);
        padding-bottom: rem-calc(120);
    }
}
.menu-desktop {
    background: $primary-color;
    display:none;
    .grid-container {
        .top-bar {
            display: grid !important;
            grid-template-columns: 20% 80% !important;
            background-color: transparent;
            color: $white;
            padding: 0;
            margin: 0;
            .top-bar-left {
                .menu {
                    background-color: transparent;
                    color: $white;

                    .menu-text {
                        display: contents;
                        img {
                            width: 100%;
                            height: 65px;
                        }
                    }
                    
                }
            }
            .top-bar-right {
                width: 100%;
                .menu {
                    width: 100%;
                    background-color: transparent;
                    display: grid;
                    grid-template-columns: 2fr 1fr 2fr;
                    @include breakpoint(986){
                        grid-template-columns: repeat(3, 1fr);
                    }
                    grid-gap: 1em;
                    li {
                        a { 
                            color: $white;
                            font-weight: 600;
                            text-align: center;
                            border-bottom: 2px solid transparent;
                            transition: all 0.15s ease-in;
                            background: transparent;
                            font-size: 16px;

                            &:hover {
                                border-bottom: 2px solid $secondary-color;
                                background: transparent;
                                transition: all 0.15s ease-in;
                            }
                        }
                        &:first-child {
                            justify-self: start;
                        }
                        &:nth-child(2) {
                            justify-self: center;
                        }
                        &:nth-child(3) {
                            justify-self: end;
                            a {
                                transition: all 0.15s ease-in;
                                background: $alert-color;
    
                                &:hover {
                                    border-bottom: 2px solid $secondary-color;
                                    background: $secondary-color;
                                    transition: all 0.15s ease-in;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(930) {
        display:block;
    }
}
.goodtoknow {
    background: $secondary-color;
    text-align: center;
    padding-bottom: rem-calc(15);
    padding-top: rem-calc(5);
    div {
        img {
            max-width: 17%;
            min-width: rem-calc(120);
        }
        h4 {
            font-weight: bold;
            letter-spacing: 0.5px;
            position: relative;
            top: -10px;
            margin-bottom: -5px;
        }
    }
}
.health {
    padding-bottom: rem-calc(50);
}
.careers {
    background: $primary-color;
    padding-bottom: rem-calc(50);
    .title {
        color: $white;
    }
}
.careers-opportunities {
    background: $secondary-color;
    padding-bottom: rem-calc(25);
    .title {
        color: $primary-color;
        &:after {
            border-bottom: 5px solid $alert-color;
        }
    }
    p {
        color: $primary-color;
        max-width: rem-calc(820);
        text-align: center;
        margin: 0 auto;
        padding-bottom: rem-calc(50);
        font-size: rem-calc(20);
        font-weight: 600;
        line-height: rem-calc(25);
    }
    div {
        text-align: center;
        a {
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: rem-calc(25);
            font-size: rem-calc(19);
            padding: rem-calc(10);
            font-weight: 500;
            &:hover {
                background: #2d2d2c !important;
            }
        }
    }
}

footer {
    background: $primary-color;
    color: $white;
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(5);
    width: 100%;
    .footer-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 50px;
        .footer-item {
            &:first-child {
                justify-self: center;
                p {
                    text-align: center;
                    font-size: rem-calc(12);
                }
            }
            &:last-child {
                justify-self: end;
                img {
                    position: relative;
                    margin-top: rem-calc(-10);
                    max-width: 50px;
                    width: 80%;
                }
            }
        }
    }
}
.menu-page {
    display: inherit;
    background: transparent;
    position: relative;
    @include breakpoint(930) {
        background: $primary-color;
        position: initial;


    }
    height: rem-calc(52);
    .menu-blog {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
        list-style: none;
    
        .menu-blog-item {
            width: 33%;
            order: 0;
            flex: 1 1 auto;
            align-self: auto;
            .topbar-responsive {
                padding: 0;
                display: block;
                @include breakpoint(930) {
                    display: none;
                }

                .top-bar-title {
                    top: 20px;
                }
            }
            img {
                width: rem-calc(180);
                                display: none;
                @include breakpoint(930) {
                    display: initial;
                    text-align: center;
                }
            }
            .goback-item {
                display: grid;
                grid-template-columns: 55px 90%;
                justify-items: start;
                align-items: end;
                padding-top: rem-calc(8);
                z-index: 700;
                position: fixed;
                top: 0 !important;
                left: -5px;
                padding-left: rem-calc(15);
                @include breakpoint(930) {
                    position: relative;
                    top: 0;

                }
                .goback {
                    display: none;
                }
                @include breakpoint(440) {
                    .goback {
                        display: initial;
                    }
                    //here
                }
                .img-wrapper {
                    min-width: 55px;
                    .back {
                        width: 50px;
                    }
                }
                p {
                    color: $white;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: rem-calc(18);
                    height: 45px;
                    line-height: 35px;
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .menu-blog-item:nth-child(1) {
            text-align: left;
        }
        .menu-blog-item:nth-child(2) {
            text-align: center;
        }
        .menu-blog-item:nth-child(3) {
            text-align: right;
        }
    }
    .topbar-responsive-links {
        animation-fill-mode: both;
        animation-duration: 0s;
        width: 100%;
        position: relative;
        top: -55px;
        background: $primary-color;

        div {
            position: relative;
            z-index: 2000;
            ul {
                padding-top: 40px;
                padding-bottom: 40px;
                li {
                    font-size: 30px;
                    padding-top: 40px;
                    padding-bottom: 20px;
                    &:last-child {
                        padding-bottom: 40px;
                    }
                    a {
                        color: $white;
                    }
                }
            }
        }
    
        @if $topbar-responsive-animation-type == slide-down {
          animation: slideDown 1s ease-in;
        } @else if $topbar-responsive-animation-type == fade-in {
          animation: fadeIn 1s ease-in;
        } @else {
          animation: none;
        }
      }
}
.more-content {
    background: $light-gray;
    padding-bottom: rem-calc(50);
}

.bottom-bar {
    padding-bottom: rem-calc(20);
    padding-top: rem-calc(20);

    .bottom-bar-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 10px;
        @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 2em;
            padding: 0 15px;
        }
        width: 100%;

        .bottom-bar-left {
            order: 1;
            justify-self: start;

            p.blog-tag {
                font-weight: 600;
            }
        }

        .bottom-bar-center {
            order: 2;
            justify-self: end;
            img {
                width: 120px !important;
            }
        }
        .bottom-bar-center-small {
            order: 2;
            justify-self: end;
            img {
                width: 65px !important;
            }
        }

        .bottom-bar-right {
            display: none;
            order: 2;
        }

        @include breakpoint(medium) {

            .bottom-bar-left {
                line-height: 60px;
                justify-self: start;
                order: 1;
            }
            .bottom-bar-center {
                line-height: 60px;
                justify-self: center;
                order: 3;
                .img {
                    width: 100px !important;
                    padding-top: 10px;
                }
            }
            .bottom-bar-center-small {
                line-height: 60px;
                justify-self: center;
                order: 3;
                .img {
                    width: 60px !important;
                    padding-top: 10px;
                }
            }
            .bottom-bar-right {
                justify-self: end;
                display: initial;
                order: 3;
            }
        }
    }
}
.social-share {
    display: none;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    @include breakpoint(930) {
        display: flex;
    }
    .social-share-title {
        font-size: rem-calc(12);
        font-weight: 600;
        text-transform: uppercase;
        color: $primary-color;
        margin-top: 22px;
        min-width: 100px;
    }
    a {
        padding: 10px 10px 0 10px;
        width: 60px;
        &:last-child { 
            padding: 10px 0 0 10px;
            width: 50px;
        }
    }
}
.social-share-mobile-title {
    display: block;
    width:100%;
    font-size: rem-calc(12);
    font-weight: 600;
    text-transform: uppercase;
    color: $primary-color;
    margin-top: 22px;
    min-width: 100px;
    text-align: center;
    @include breakpoint(930) {
        display: none;
    }
}
.social-share-mobile-content {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    a {
        &:last-child {
            padding-right: 10px;
        }
        img {
            width: 40px;
        }
        padding-bottom: 10px;
    }
    @include breakpoint(930) {
        display: none;
    }
}
.blog-content {
    
    .blog-title {
        font-size: rem-calc(38);
        line-height:  rem-calc(42);
        font-weight: bold;
        margin-top: rem-calc(20);
        letter-spacing: 0.5px;
    }
    .blog-subtitle {
        font-size: rem-calc(20);
        font-weight: 800;
        line-height: rem-calc(27);
        margin-top: rem-calc(20);

    }
    .blog-subtitle-desc {
        font-size: rem-calc(23);
        font-weight: 800;
        line-height: rem-calc(10);
        margin-top: rem-calc(35);

    }
    .blog-quotation {
        position: relative;
        font-size: rem-calc(18);
        font-weight: 400;
        line-height: rem-calc(27);
        padding-left: rem-calc(20);
        margin-top: rem-calc(20);
        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-left: 8px solid $alert-color;
        }
    }
    .blog-text {
        font-size: rem-calc(18);
        font-weight: 400;
        line-height: rem-calc(27);
    }
}
.pin-tab {
    background: linear-gradient(to right, #ffbc0f 0%, #ffbc0f 50%, #ffbc0f 50%, #ffbc0f 50%, #ffbc0f 100%);
    @include breakpoint(930) {
        background: linear-gradient(to right, #ffbc0f 0%,#ffbc0f 50%,#ffffff 50%,#ffffff 50%,#ffffff 100%);
}
    .pin-tab-content {
        background: $secondary-color;
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
        margin-bottom: rem-calc(0);
        @include breakpoint(930) {
            margin-bottom: rem-calc(50);

        }
        .pin-tab-title {
            font-size: rem-calc(23);
            font-weight: 800;
            line-height: rem-calc(10);
            margin-top: rem-calc(20);
    
        }
        .pin-tab-desc {
            font-size: rem-calc(18);
            font-weight: 400;
            line-height: rem-calc(27);
        }
    }
}
//menu
$topbar-responsive-bg: $primary-color;
$topbar-responsive-animation-type: fade-in; // or use slide-down or none

.topbar-responsive {
  background: $topbar-responsive-bg;
  padding: 0 !important;
  position: relative;
  top: -2px;

  .topbar-responsive-logo {
    color: $white;
    vertical-align: middle;
    position: relative;
    left: calc(50% - 82px);
  }

  .menu {
    background: $topbar-responsive-bg;

    li {
        font-size: rem-calc(30);
        padding: rem-calc(40 0);
        text-align: center;
        
    }
    li:first-child {
        padding-top: rem-calc(40);
    }
    li:last-child {
        padding-bottom: rem-calc(40);
    }

    li:last-of-type {
      margin-right: 0;
    }

    a {
      color: $white;
      transition: color 0.15s ease-in;

      &:hover {
        color: lighten($topbar-responsive-bg, 60%);
      }

      @media screen and (max-width: 930px) {
        padding: 0.875rem 0;
      }
    }

    .topbar-responsive-button {
      color: $white;
      border-color: $white;
      border-radius: 5000px;
      transition: color 0.15s ease-in, border-color 0.15s ease-in;

      &:hover {
        color: lighten($topbar-responsive-bg, 60%);
        border-color: lighten($topbar-responsive-bg, 60%);
      }

      @media screen and (max-width: 930px) {
        width: 100%;
        margin: 0.875rem 0;
      }
    }
  }
  

//    @media screen and (max-width: 930px) {
//     //  padding: 0.75rem;

//     .top-bar-title {
//       position: relative;
//       width: 100%;
//       border: 3px solid green;

//       span {
//         position: absolute;
//         border-radius: 5px;
//         .menu-icon {
//           margin-bottom: 4px;
//         }
//       }
//     }
//   }
}

@keyframes fadeIn {
  from {
    opacity:0;
  } to {
    opacity:1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@include breakpoint(930) {
  .topbar-responsive-links {
    animation-fill-mode: both;
    animation-duration: 0.5s;
    width: 100%;

    @if $topbar-responsive-animation-type == slide-down {
      animation: slideDown 1s ease-in;
    } @else if $topbar-responsive-animation-type == fade-in {
      animation: fadeIn 1s ease-in;
    } @else {
      animation: none;
    }
  }
}
.menu-mobile {
    position: fixed;
    width: 100%;
    z-index: 600;
    top: -6px;
    @include breakpoint(930) {
        display: none;
    }
}


//vimeo
#video {
    background: black;
}
div#modal-video {
    width: 60%;
}

.video-vimeo {
    position: relative;
    display: flex;
    img {
        margin: 0 auto;
    }
    .mc-play {
        width: 110px;
        height: 110px;
        position: absolute;
        top: calc(50% - 55px);
        left: calc(50% - 55px);
        img {
            width: 110px;
        }
    }
}

//reveal
.reveal {
    padding: 2em;
    background: transparent;
    border: 0;
    outline: none;
  }
  
  .close-button {
    position: absolute;
    top: -0.2em;
    color: rgb(255,255,255);
    text-shadow: 2px 2px 8px rgb(0,0,0);
    display: block;
    outline: none;
    :hover {
      color: rgb(142, 216, 248);
      text-shadow: 2px 2px 5px rgb(0,0,0);
    }
  }

  //cookies

  a.qc-cmp-persistent-link {
      background: $primary-color !important;
      left:0 !important;
      max-width: 110px !important;
}

//mobile-social-share
.mobile-social-share {
    text-align: center;
    display: block;
    margin-bottom: rem-calc(20);
    @include breakpoint(930) {
        display: none;

    }
}
//hamburger start
.hamburger {
    &:hover {
        cursor: pointer;
    }
    .line{
        position: absolute;
        border-radius: 4px;
        text-align: center;
        width: rem-calc(32px);
        height: rem-calc(4px);
        background-color: #ecf0f1;
        transition: all 0.3s ease-in-out;
        &:first-child {
            top: 20% !important;
        }
        &:nth-child(2) {
            top: calc(50% - 2px) !important;
        }
        &:nth-child(3) {
            bottom: 20% !important;
        }
      }
}
  
  /* ONE */
  
  #hamburger-1.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  #hamburger-1.is-active .line:nth-child(1){
    transform: translateY(9px) rotate(45deg);
  }
  
  #hamburger-1.is-active .line:nth-child(3){
    transform: translateY(-9px) rotate(-45deg);
  }
  //hamburger end


  img.brands-desktop {
    margin-top: rem-calc(20px);
    display: none;
    @include breakpoint(930) {
        display: block;

    }
  }
  img.brands-mobile {
      display: initial;
      margin-top: rem-calc(20px);
      @include breakpoint(930) {
        display: none;

    }
  }

  .gtk-logo-small {
      width: rem-calc(90px) !important;
      position: relative !important;
      top: 0px !important;
  }